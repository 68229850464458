import { ref, reactive, computed, onMounted } from "vue";
import * as API from "@/API/checking/spotChecking";
import { SearchParams, DealerTableData, AFCandLCTableData } from "@/views/Checking/spotChecking/spotCheckingVonder/pendingChecking/types";
import moment from 'moment';
import useFetch from "@/hooks/useFetch";
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getSubscribedBu } from "@/API/approve";
import useThousands from "@/utils/payment/useThousands";
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';

const useSampleCheck = () => {
    const router = useRouter()

    const startDate = moment()
        .month(moment().month())
        .startOf("month")
        .format("YYYY-MM-DD");
    const endDate = moment()
        .month(moment().month())
        .endOf("month")
        .format("YYYY-MM-DD");

    const searchParams = reactive<SearchParams>({
        entity: undefined,
        bu: undefined,
        submissionPeriod: [startDate, endDate],
        priority: undefined
    })

    const dealerColData = computed(() => {
        return [
            { title: "", dataIndex: "light", slots: { customRender: 'light' }, width: 20 },
            { title: "Package Code", dataIndex: "packageCode", width: 230 },
            { title: "Time Line From", dataIndex: "timeLineFrom", slots: { customRender: 'timeLineFrom' }, width: 150 },
            { title: "To", dataIndex: "to", slots: { customRender: 'to' }, width: 100 },
            { title: "Program No.", dataIndex: "programNo", customRender: useThousands(), align: 'right', width: 150 },
            { title: "Confirmed No.", dataIndex: "confirmNo", customRender: useThousands(), align: 'right', width: 150 },
            { title: "Assignee", dataIndex: "assignee", width: 150 },
            { title: "Priority", dataIndex: "priority", width: 150 },
            { title: "Status", dataIndex: "status", width: 150 },
            { title: "Last Update Time", dataIndex: "lastUpdateTime", slots: { customRender: 'lastUpdateTime' }, width: 150 },
            { title: "Operation", fixed: 'right', dataIndex: "operation", slots: { customRender: 'operation' }, align: 'left', width: 120 }
        ]
    })
    const afcAndLcColData = computed(() => {
        return [
            { title: "", dataIndex: "light", slots: { customRender: 'light' }, width: 20 },
            { title: "Payout Round", dataIndex: "payoutRound", width: 220 },
            { title: "Time Line From", dataIndex: "timeLineFrom", slots: { customRender: 'timeLineFrom' }, width: 150 },
            { title: "To", dataIndex: "to", slots: { customRender: 'to' }, width: 100 },
            { title: "Program No.", dataIndex: "programNo", customRender: useThousands(), align: 'right', width: 150 },
            { title: "Confirmed No.", dataIndex: "confirmNo", customRender: useThousands(), align: 'right', width: 150 },
            { title: "Assignee", dataIndex: "assignee", slots: { customRender: 'assignee' }, width: 200 ,align:'center'},
            { title: "Priority", dataIndex: "priority", width: 150 },
            { title: "Status", dataIndex: "status", width: 150 },
            { title: "Last Update Time", dataIndex: "lastUpdateTime", slots: { customRender: 'lastUpdateTime' }, width: 150 },
            { title: "Operation",fixed: 'right', dataIndex: "operation", slots: { customRender: 'operation' }, align: 'center', width: 100 }
        ]
    })
    const dealerTblData = ref<DealerTableData[]>()
    const afcAndLcTblData = ref<AFCandLCTableData[]>()
    const getTableData = (type: string) => {
        const params = {
            entityList: searchParams.entity,
            buList: searchParams.bu,
            submissionPeriodStart: searchParams.submissionPeriod[0].replace(/-/g, '/'),
            submissionPeriodEnd: searchParams.submissionPeriod[1].replace(/-/g, '/'),
            priority: searchParams.priority ? searchParams.priority : ''
        }
        if (type === 'dealer') {
            API.getSampleCheckDealerList(params).then((data: any) => {
                dealerTblData.value = data
            })
        } else {
            // 金融bu、entity不参与筛选
            params.buList = undefined
            params.entityList = undefined
            API.getSampleCheckFinanceList(params).then((data: any) => {
                afcAndLcTblData.value = data
            })
        }
    }
    const doSearch = () => {
        dealerTblData.value = []
        afcAndLcTblData.value = []
        getTableData('dealer')
        getTableData('afcAndLc')
    }
    const doReset = () => {
        Object.assign(searchParams, {
            entity: undefined,
            bu: [],
            submissionPeriod: [startDate, endDate],
            priority: undefined
        })
        doSearch()
    }

    //#region sampling dealer
    const samplingDealerClick = (record: DealerTableData) => {
        const params = {
            packageCodeId: record.packageCodeId
        }
        API.getSampleCheckDealerSampling(params).then(res => {
            if (res.code === '0') {
                message.success('Successfully Sampling!')
            } else {
                message.warning(res.message)
            }
        })
    }
    //#endregion
    
    //#region sampling 金融
    const samplingFinanceClick = (record: AFCandLCTableData) => {
        const params = {
            payoutRoundId: record.payoutRoundId,
            payoutRoundType: record.type
        }
        API.getSampleCheckFinanceSampling(params).then(res => {
            if (res.code === '0') {
                message.success('Successfully Sampling!')
            } else {
                message.warning(res.message)
            }
        })
    }
    //#endregion
    
    onMounted(() => {
        doSearch()
    })
    return {
        searchParams,
        dealerColData,
        afcAndLcColData,
        dealerTblData,
        afcAndLcTblData,
        doSearch,
        doReset,
        samplingDealerClick,
        samplingFinanceClick
    }
}
export default useSampleCheck