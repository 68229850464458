
import { defineComponent } from "vue";
import useSampleCheck from "@/hooks/checking/spotChecking/useSampleCheck";
import { elementSize } from "@/utils";
import { EyeOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
// import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import AllEntitySelector from "@/views/Payment/components/AllEntitySelector.vue";
import CollapseWrapper from "@/views/Payment/components/CollapseWrapper.vue";
// import OperationWrapper from "@/views/Payment/components/OperationWrapper.vue";
export default defineComponent({
  name: "paymentApproveMgmt",
  components: {
    EyeOutlined,
    BuSelector,
    // EntitySelector,
    AllEntitySelector,
    // CollapseWrapper,
    // OperationWrapper,
  },
  props: {},
  setup() {
    const { commit } = useStore();
    //#region [引用useApprovalMain.ts相关]
    const {
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      samplingDealerClick,
      samplingFinanceClick,
    } = useSampleCheck();

    const tableSize = elementSize(".ant-table-wrapper");
    const router = useRouter();
    const handleGoToDealer = (record: any) => {
      commit(
        "spotChecking/updateSampleCheckPackagecodeId",
        record.packageCodeId
      );
      commit("spotChecking/updateSampleCheckPackagecode", record.packageCode);
      router.push({ path: "/sampleCheckDealer" });
    };
    const handleGoToFl = (record: any) => {
      commit("spotChecking/updateSampleCheckPayoutRound", {
        payoutRoundId: record.payoutRoundId,
        payoutRound: record.payoutRound,
        type: record.type,
      });
      router.push({ path: "/sampleCheckFl" });
    };
    return {
      handleGoToDealer,
      handleGoToFl,
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      tableSize,
      samplingDealerClick,
      samplingFinanceClick,
    };
  },
});
